import './Home.css';
import { Outlet, Link, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from 'react'

import CarCard from "../../components/CarCard/CarCard";
import ContactForm from "../../components/ContactForm/ContactForm";
import ReservationContainer from '../Reservation/ReservationContainer';

import { useJsApiLoader, Autocomplete } from '@react-google-maps/api';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBaby, faBottleWater, faBatteryThreeQuarters, faWifi, faCreditCard, faCircleRight, faLocationDot, faMoneyBill, faCar, faFacebookF } from '@fortawesome/free-solid-svg-icons'

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';

import Button from '@mui/joy/Button';

const PrestationSteps = [
  {
    title: 'Votre trajet',
    text: 'Choisissez votre point de départ et d\'arrivée, ainsi que la date et l\'heure souhaitées pour votre voyage.',
    icon: faLocationDot
  },
  {
    title: 'Paiement',
    text: "Sélectionnez le mode de paiement de votre choix, en ligne ou directement avec le chauffeur.",
    icon: faMoneyBill
  },
  {
    title: 'Relax',
    text: 'Vous êtes prêt ! Nous nous occupons du reste pour vous assurer un voyage confortable et sécurisé, avec un personnel de conduite professionnel et expérimenté.',
    icon: faCar
  },
];

const faqData = [
  {
      question: 'Quelles sont les mesures prises pour assurer un transfert en toute sécurité depuis les aéroports et les gares ?',
      answer: "Nous prenons des mesures strictes en matière de sécurité, telles que des conducteurs agréés, des véhicules bien entretenus et des protocoles de désinfection réguliers.",
  },
  {
      question: "Comment fonctionne le service de déplacements longue distance en France ? Y a-t-il des arrêts prévus en cours de route ?",
      answer: "Notre service de déplacements longue distance vous offre un trajet direct sans arrêts prévus en cours de route, pour vous garantir une arrivée rapide à votre destination.",
  },
  {
      question: "Quelles sont les options de personnalisation disponibles pour les déplacements flexibles ?",
      answer: "Vous avez la possibilité de personnaliser votre trajet en choisissant l'heure de départ, la prise en charge à domicile et en bénéficiant de retards de paiement pour une flexibilité maximale.",
  },
  {
    question: "Quelle est la procédure pour demander l'installation d'un siège enfant dans le véhicule ?",
    answer: "Il vous suffit de mentionner votre demande lors de la réservation, et nous nous assurerons de fournir un siège enfant adapté à votre trajet.",
},
{
  question: "Comment fonctionne la connexion Wi-Fi à bord des véhicules ? Est-ce qu'elle est gratuite pour les passagers ?",
  answer: "Nous offrons une connexion Wi-Fi gratuite à bord de nos véhicules, afin que vous puissiez rester connecté(e) et profiter d'Internet pendant votre trajet.",
},
{
  question: "Est-ce que vous acceptez tous les types de cartes bancaires pour le paiement à bord ?",
  answer: "Oui, nous acceptons toutes les principales cartes bancaires, vous offrant ainsi la possibilité de payer à bord par carte de manière pratique et sécurisée.",
},
];

const PrestationPresentations = [
  {
    title: 'Transferts Aéroport et Gares avec Tarif-VTC.fr',
    imagesUrl: '/vtc_gare.webp',
    alt: "chauffeur privé ouvrant la porte et aider une cliente en costume à sortir d'une voiture privé mercedes",
    text: 'Tarif-VTC, le spécialiste en transport de qualité, propose des services fiables et confortables depuis les aéroports de l\'Île-de-France et les grandes gares de Paris.',
    linkUrl: '/nos-services/aeroport'
  },
  {
    title: 'Déplacements longue distance en France avec Tarif-VTC.fr',
    imagesUrl: '/longues_distances.webp',
    alt: "chauffeur privé en costume noir portant des gants noir tenant le levier de vitesse d'une voiture vtc",
    text: 'Le choix idéal pour vos déplacements de longue distance en France. Nous offrons une flotte de véhicules haut de gamme pour assurer un voyage confortable et fiable.',
    linkUrl: '/nos-services/longues-distances'
  },
  {
    title: 'Mise à disposition de Chauffeur Privé pour Vos Besoins',
    imagesUrl: '/mise_a_dispo.webp',
    alt: "client lisant un journal dans une voiture intérieur noir avec un chauffeur portant un costume",
    text: 'Nous proposons un service de mise à disposition de chauffeurs à l\'heure pour des déplacements urbains ou des excursions d\'une journée.',
    linkUrl: '/nos-services/mise-a-dispo'
  },
];

const carFiles = ['businessCar','businessVan','luxuryCar'];
const carDataMap = carFiles.map((carFile) => require(`../../data/${carFile}.json`));
const libraries = ['places'];

export default function Home() {
  const [carDataMap, setCarDataMap] = useState([]);

  const [preFormData, setPreFormData] = useState({
    adresse_start: '',
    adresse_end: '',
    date: new Date().toISOString().split('T')[0],
    departure_time: '11:00',
  });
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  const [autocompleteStart, setAutocompleteStart] = useState(null);
  const [autocompleteEnd, setAutocompleteEnd] = useState(null);
  const [activeSlide, setActiveSlide] = useState(0);

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: 'AIzaSyBDZl1s5PQ6TREMrz-OmiXLu2dDtIGkm8Q',
    libraries,
  });


  useEffect(() => {
    window.scrollTo(0, 0);

    // Importez dynamiquement les fichiers JSON
    const dataMap = carFiles.map((carFile) => require(`../../data/${carFile}.json`));
    setCarDataMap(dataMap);
  }, []); // Le tableau vide en tant que deuxième argument signifie que cela s'exécutera une seule fois au chargement du composant


  const navigate = useNavigate();

  const handlePlaceChanged = (field, autocomplete) => () => {
    if (autocomplete) {
      const place = autocomplete.getPlace();
      const addressComponents = place.address_components;
  
      let streetNumber = '';
      let streetName = '';
      let city = '';
      let postalCode = '';
      let country = '';
  
      addressComponents.forEach((component) => {
        if (component.types.includes('street_number')) {
          streetNumber = component.long_name;
        }
        if (component.types.includes('route')) {
          streetName = component.long_name;
        }
        if (component.types.includes('locality')) {
          city = component.long_name;
        }
        if (component.types.includes('postal_code')) {
          postalCode = component.long_name;
        }
        if (component.types.includes('country')) {
          country = component.long_name;
        }
      });
  
      const formattedAddress = `${streetNumber} ${streetName}, ${postalCode} ${city}, ${country}`;
  
      setPreFormData((prevData) => ({
        ...prevData,
        [field]: formattedAddress,
      }));
    }
  };
  
  const onLoadStart = (autocompleteInstance) => {
    setAutocompleteStart(autocompleteInstance);
  };
  
  const onLoadEnd = (autocompleteInstance) => {
    setAutocompleteEnd(autocompleteInstance);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPreFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const queryParams = new URLSearchParams(preFormData).toString();
    //navigate('/reservation', { state: preFormData });
    navigate(`/Reservation?${queryParams}`);
  };

  const handleSlideChange = (index) => {
    setActiveSlide(index);
  };

    return (
      <>

        <main className="main">

          <section className="header_section" id="header_section">
            <div className="header_section_content">

                <div className="title_content">
                  <img src="/logo_white_header.webp" alt="tarif vtc logo"/>
                  <p>Votre partenaire au quotidien</p>
                </div>


            {isLoaded ? (
            <form onSubmit={handleSubmit} className='Pre_form'>
              <h2> Réserver un chauffeur VTC maintenant </h2>
              <div className='PreFormLocationDeparture'>
                <label>Adresse de départ:</label>
                <Autocomplete onLoad={onLoadStart} onPlaceChanged={handlePlaceChanged('adresse_start', autocompleteStart)}>
                  <input
                    type="text"
                    name="adresse_start"
                    value={preFormData.adresse_start}
                    onChange={handleChange}
                    required
                  />
                </Autocomplete>
              </div>
              <div>
                <label>Adresse d'arrivée:</label>
                <Autocomplete onLoad={onLoadEnd} onPlaceChanged={handlePlaceChanged('adresse_end', autocompleteEnd)}>
                  <input
                    type="text"
                    name="adresse_end"
                    value={preFormData.adresse_end}
                    onChange={handleChange}
                    required
                  />
                </Autocomplete>
              </div>
              <div>
                <label>Date de départ:</label>
                <input
                  type="date"
                  name="date"
                  value={preFormData.date}
                  onChange={handleChange}
                  required
                />
              </div>
              <div>
                <label>Heure de départ:</label>
                <input
                  type="time"
                  name="departure_time"
                  value={preFormData.departure_time}
                  onChange={handleChange}
                  required
                />
              </div>
              <button type="submit">Réserver</button>
            </form>
          ) : (
            <div>Loading...</div>
          )}


        </div>







          </section>

          <section className="body_section">

            <section className='head_title_section'>
              <div className='head_title_content'>
                <h3> Chauffeur privé en région parisienne</h3>
                <p className='header_section_subText'>Disponible 7j/7 24h/24 - Réservation rapide par <a href="tel:0650240998">téléphone</a></p>
                <div className='social_media_icons'>
                  <a href="https://www.facebook.com/drivigo"><img src="/facebook_logo.png" alt="tarif vtc facebook"/></a>
                  <a href="https://www.instagram.com/mdprive_off/"><img src="/instagram_logo.webp" alt="tarif-vtc.fr logo instagram"/></a>
                </div>
              </div>
              <div className="reservation_link"><Link to='/reservation'><Button variant="soft"> Réservez </Button></Link><Link to='/montrajet'><Button variant="soft"> Retrouver mon trajet </Button></Link></div>
            </section>         

            <section className="presentation_section" id="presentation">
                <div className="presentation_section_content">
                  <img src="/chauffeur-elegant.webp" alt="chauffeur privé vtc portant un costume ouvrant une portière de voiture privé noir de la marque mercedes"/>
                  <div className='presentation_text'>
                      <h1> Tarifs VTC à Roissy, Orly, et Disney. </h1>
                      <p> Bienvenue chez <b>Tarif-VTC</b>, votre <b>spécialiste en transport de personnes</b> de qualité supérieure. Nous sommes là pour vous offrir des solutions de transport adaptées à tous vos besoins, que ce soit pour un <b>déplacement professionnel</b>, une soirée exceptionnelle ou un simple transfert à l&apos;aéroport.</p>
                  </div>
                </div>
            </section>

            <section className="nos_services_section" id="nos_services">
              <div className="nos_services_container">
                <div className='nos_services_content'>
                  <h2> NOS SERVICES DE TRANSPORT VTC </h2>
                  <div className='nos_services_item_content'>
                    {PrestationPresentations.map((item, index) => (
                      <div className="nos_services_item" key={item.title + index}>
                        <img src={item.imagesUrl} alt={item.alt}/>
                        <div className="nos_services_item_content">
                          <h3>{item.title}</h3>
                          <p>{item.text}</p>
                          <Link to={item.linkUrl}>Découvrir</Link>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </section>

            <section className="reserver_son_trajet_section" id="reserver_son_trajet">

              <div className="reserver_son_trajet_container">
                <h2> COMMENT RÉSERVER VOTRE TRAJET AVEC TARIF-VTC.fr </h2>

                <div className="reserver_son_trajet_steps">

                  <Card sx={{ maxWidth: 500 }} className='card_travel'>
                    <CardActionArea>
                      <CardMedia
                        component="img"
                        height="250"
                        image="/map.webp"
                        alt="carte google map avec une pin rouge planté sur la carte indicant un chemin en jaune"
                        width="500px"
                      />
                      <CardContent>
                        <Typography gutterBottom variant="h4" component="div">
                          Votre trajet
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            Choisissez votre point de départ et d'arrivée, ainsi que la date et l'heure souhaitées pour votre voyage.
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>

                  <Card sx={{ maxWidth: 500 }} className='card_travel'>
                    <CardActionArea>
                      <CardMedia
                        component="img"
                        height="250"
                        image="/phone.webp"
                        alt="Mains d'une personne tenant un téléphone noir et une carte de crédit rouge au dessus d'une table"
                        width="500px"
                      />
                      <CardContent>
                        <Typography gutterBottom variant="h4" component="div">
                        Paiement
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          Sélectionnez le mode de paiement de votre choix, en ligne ou directement avec le chauffeur.
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>

                  <Card sx={{ maxWidth: 500 }} className='card_travel'>
                    <CardActionArea>
                      <CardMedia
                        component="img"
                        height="250"
                        image="/paiement.webp"
                        alt="Mains d'une personne tenant un téléphone et validant un achat en ligne sur le téléphone"
                        width="500px"
                      />
                      <CardContent>
                        <Typography gutterBottom variant="h4" component="div">
                        Relax
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                        Vous êtes prêt ! Nous nous occupons du reste pour vous assurer un voyage confortable et sécurisé.
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </div>
              </div>

              <section className="reservation_link_section" id="reservation_link">
                <div className="reservation_link_content">
                    <div className="reservation_link_left">
                        <p>Voyagez avec élégance grâce à notre service VTC premium.</p>
                        <p>Réservez votre expérience de première classe en un clic !</p>
                        </div>
                    <div className="reservation_link_right"><Link to="/reservation"><Button variant="soft"> Réservez </Button></Link></div>
                </div>
            </section>
            </section>

            <section className="eco_section" id="eco">
              <div className="eco_container">
                
                <h2 className='eco_title'>Trajets Économiques : Voyagez Intelligent, Payez Moins</h2>

                <p>Découvrez notre service de trajets économiques, conçu pour offrir un transport confortable et fiable à des tarifs abordables. Que ce soit pour vos déplacements quotidiens ou vos voyages occasionnels, notre flotte de véhicules économiques vous assure un trajet agréable sans compromettre la qualité. Profitez d'un excellent rapport qualité-prix et voyagez sereinement tout en maîtrisant votre budget. Avec nos trajets économiques, le confort et l'économie vont de pair.</p>

                <Link to="/eco"> Découvrire l'offre </Link>
              
              </div>

            </section>

            <section className="cars_list_section" id="cars_list">
              <div className="cars_list_container">
                <h2>DÉCOUVREZ NOTRE FLOTTE DE VÉHICULES CONFORTABLES</h2>
                <div className="cars_list_cards">
                {carDataMap.map((carData, index) => (
                    <CarCard imgUrl={carData.img} alt={carData.alt} carType={carData.name} brand={carData.marque + ' ' + carData.model} passengerNb={carData.person} LuggageNb={carData.luggage} addLuggage={carData.additional_luggage} linkUrl={carData.linkUrl} key={index} />
                  ))}
                </div>
              </div>
            </section>
                 
            <section className="car_inside_services_section" id="car_inside_services">
              <div className="car_inside_services_container">
                <h3>Expérience de Conduite de Qualité avec Nos Voitures</h3>
                <div className="car_inside_services_content">
                    <div className="car_inside_services_item">
                      <FontAwesomeIcon icon={faBaby} className="iconCustom"/>
                      <p>Siège enfant</p>
                    </div>
                    <div className="car_inside_services_item">
                      <FontAwesomeIcon icon={faBottleWater} className="iconCustom"/>
                      <p>Eau</p>
                    </div>
                    <div className="car_inside_services_item">
                      <FontAwesomeIcon icon={faBatteryThreeQuarters} className="iconCustom"/>
                      <p>Chargeurs</p>
                    </div>
                    <div className="car_inside_services_item">
                      <FontAwesomeIcon icon={faWifi} className="iconCustom"/>
                      <p>Wifi / Bluethooth</p>
                    </div>
                    <div className="car_inside_services_item">
                      <FontAwesomeIcon icon={faCreditCard} className="iconCustom"/>
                      <p>Paiement à bord</p>
                    </div>
                </div>
              </div>
            </section>

            <section className="Faq_section" id="FAQ">
              <div className="Faq_content">
                <div className="Faq_content_title">
                  <h3>Besoin de Plus de Détails ?</h3>
                  <h4>Posez-nous Vos Questions</h4>
                </div>
                {faqData.map((item, index) => (
                    <div className="Faq_content_text" key={item.question + index}>
                      <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography>{item.question}</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>{item.answer}</Typography>
                      </AccordionDetails>
                      </Accordion>
                    </div>
                ))}
              </div>
            </section>

            <section className="contact_section" id="contact">
              <div className="contact_content">
                <div className="contact_content_left">
                    <h2>Contactez-nous pour plus d’informations</h2>
                    <a href="tel:0650240998"><Button variant="soft"> Prendre un rendez-vous </Button></a>
                </div>
                <div className="contact_content_right">
                <img src="/logo_white.webp" alt="tarif-vtc.fr logo blanc" width={300} height={300}/>
                </div>
              </div>
            </section>

          </section>

        </main>

      </>
  )
}


/*             



                  {PrestationSteps.map((item, index) => (
                        <div className="prestationSectionStep" key={item.title + index}>
                          <div className='prestationSectionIcon_title'>
                            <FontAwesomeIcon icon={item.icon} />
                            <div>{item.title}</div>
                          </div>
                          <p>{item.text}</p>
                        </div>
                    ))}


            <section className="bottomContactSection" id="contact">
              <div className="bottomContactSectionContent">
                    <h2>Contactez nous pour plus d’informations</h2>
                    <p className="bottomContactPhoneNb">06 50 24 09 98</p>
                    <div className="bottomContactPhoneDivide"></div>
                    <a href="tel:0650240998"><Button variant="soft"> Prendre un rendez-vous </Button></a>
              </div>
            </section>







<section className="adverstisingSection">
              <div className="adverstisingSectionContent">
                <div className="adverstisingSectionContentLeft">
                    <h2>Partenaire de tous vos déplacements au quotidien.</h2>
                    <p>Tarif-VTC.fr service de chauffeur privée en région parisienne</p>
                </div>
                <div className="adverstisingSectionContentRight">
                  <img src="/logo_white.png" alt="tarif vtc logo" width={300} height={300}/>
                </div>
              </div>
            </section>

            */