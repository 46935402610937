import React, { useState, useEffect } from 'react';
import { Outlet, Link, useParams  } from "react-router-dom";
import "./MyTravel.css"
import Accordion from './../../../components/Accordion/Accordion';

export default function MyTravel({}) {
    const [travelId, setTravelId] = useState('');
    const [travelData, setTravelData] = useState(null);
    const { idTravel } = useParams();
    const [search, setSearch] = useState(false);

    const apiUrl = process.env.NODE_ENV === 'production' 
    ? process.env.REACT_APP_API_URL_PRODUCTION 
    : process.env.REACT_APP_API_URL_LOCAL;


    const handleSubmit = (event) => {
        event.preventDefault();

       getTravel(travelId);
    };

    const getTravel = async (idTravel) => {
        const url = `${apiUrl}/travel_id/${idTravel}/`;

        try {
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
            });
    
            if (!response.ok) {
                throw new Error('Failed to create reservation.');
            }
    
            const data = await response.json();
            if(data.id_compagny == 	"Tarif-vtc.fr"){
                setTravelData(data);
                setSearch(true)
            }
            else {
                setSearch(true)
                throw new Error("Le numéro de ce trajet est incorrect.");
            }
        }
        catch (error) {
          console.error('Error creating reservation:', error.message);
        }

    }

    const deleteTravel = async () => {
        if (window.confirm("Êtes-vous sûr de vouloir supprimer ce trajet ?")) {

            const url = `${apiUrl}/travel_delete/${travelId}/`;

            try {
                const response = await fetch(url, {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                });
        
                if (!response.ok) {
                    throw new Error('Failed to delete reservation.');
                }

                window.location.reload();
            }
            catch (error) {
            console.error('Error deleting reservation:', error.message);
            }

        }
    }

    const payTravel = async () => {
       const createPaymentUrl = `${apiUrl}/create-payment-session/`;

        if (!travelData) {
            console.error('Travel data is not available.');
            return;
        }

        try {
            // Créer une session de paiement
            const paymentResponse = await fetch(createPaymentUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(travelData)
            });
    
            if (!paymentResponse.ok) {
                throw new Error('Failed to create payment session.');
            }
    
            const paymentSession = await paymentResponse.json();
    
            console.log("paymentSession : ", paymentSession)
            // Rediriger l'utilisateur vers la page de paiement de Stripe
            window.location.href = paymentSession.url;
    
        } catch (error) {
            console.error('Error in payTravel:', error.message);
        }

    }


    return (
        <>
            <div className='travel_container'>

                <form onSubmit={handleSubmit} className='travel_form'>
                    <label htmlFor="travelId">Entrez l'identifiant de votre trajet :</label>
                    <input
                        type="text"
                        id="travelId"
                        value={travelId}
                        onChange={(e) => setTravelId(e.target.value)}
                        required
                    />
                    <button type="submit">Voir mon trajet</button>
                </form>



                <div className='Travel_informations'>
                    {!search ? <div> - </div> :
                    !travelData ? (
                        <div>Ce trajet est vide ou n'existe pas</div>
                    ) : travelData.status === "Cancel" ? (
                        <div className='cancel_travel_message'>Trajet annulé et n'est donc plus accessible !!</div>
                    ) : (
                        <div>
                            <h2>Détail du trajet</h2>
                            <p><span className='label_data'>Prix de la course:</span> {travelData.price_travel}</p>
                            <p><span className='label_data'>Date :</span> {travelData.date}</p>
                            <p><span className='label_data'>L'heure de départ :</span> {travelData.departure_time}</p>
                            <p><span className='label_data'>Temps estimé :</span> {travelData.time_travel} minutes</p>
                            <p><span className='label_data'>Distance :</span> {travelData.distance} km</p>
                            <p><span className='label_data'>Adresse de départ :</span> {travelData.adresse_start}, {travelData.zipcode_start} {travelData.city_start}, {travelData.country_start}</p>
                            <p><span className='label_data'>Adresse d'arrivée :</span> {travelData.adresse_end}, {travelData.zipcode_end} {travelData.city_end}, {travelData.country_end}</p>

                            <Accordion title="Informations">
                                <p><span className='label_data'>Passagers :</span> {travelData.nb_passenger}</p>
                                <p><span className='label_data'>Bagages :</span> {travelData.nb_suitcase}</p>
                                <p><span className='label_data'>Siege enfant :</span> {travelData.nb_chair_child}</p>
                                <p><span className='label_data'>BoosterSeat :</span> {travelData.nb_booster_seat}</p>
                                <p><span className='label_data'>Tablette :</span> {travelData.nb_tab}</p>

                                <div className="accordion-separator"></div>

                                <h3> Passager principal : </h3>

                                <p><span className='label_data'>Prénom :</span> {travelData.first_name}</p>
                                <p><span className='label_data'>Nom :</span> {travelData.last_name}</p>
                                <p><span className='label_data'>numéro de téléphone :</span> {travelData.phone_number}</p>
                                <p><span className='label_data'>Numéro de vol :</span> {travelData.filghtNumber}</p>
                                <p><span className='label_data'>Terminal :</span> {travelData.terminal}</p>
                            </Accordion>

                            {!travelData.payment_status ? <button onClick={payTravel}>Payez le trajet</button> : ''}
                            {travelData.status != 'Completed' ? <button onClick={deleteTravel} className='cancel_button'>Annulez le trajet</button> : "Ce trajet à été effectué, merci d'avoir voyagez avec nous !"}
                        </div>
                    )}

                </div>



        </div>
        </>
      );
    };
  